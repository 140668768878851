import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'common/components/SnackbarProvider';
import { Container, Grid, TextField, Typography } from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';

const ResetPassword: React.FC = () => {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    const handleReset = () => {
        showSnackbar({
            message: "Not yet implemented.",
            severity: "error"
        })
    }

    return (
        <Container maxWidth="md">
            <Grid
                container
                direction={'column'}
                spacing={2}
                p={4}
                component={'form'}
            >
                <Grid item textAlign={'center'}>
                    <LockResetIcon color='success' fontSize='large' />
                </Grid>
                <Grid item>
                    <Typography variant='h6' align='center'>
                        Password Reset
                    </Typography>
                </Grid>
                <Grid item mb={3}>
                    <Typography align='center'>
                        Please enter the email address for which you want to reset the password.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        id='email'
                        name="email"
                        autoComplete="email"
                        label="Email" />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth onClick={handleReset} variant="contained">Reset</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth onClick={() => navigate("/")} variant="contained">Back</Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ResetPassword;