import {
    Container,
    Typography,
    Grid,
    Paper,
    Button,
    Avatar,
    ButtonGroup,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
} from '@mui/material';
import SettingsButton from 'common/components/SettingsButton';
import { getCsrfToken, isAuthenticated } from 'common/utils/utils';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'common/components/SnackbarProvider';
import React, { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useSelector } from 'react-redux';
import { RootState } from 'common/store/store';
import UploadIcon from '@mui/icons-material/Upload';

const AccountPage: React.FC = () => {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const user = useSelector((state: RootState) => state.user);
    const [deletionAlert, setDeletionAlert] = useState(false);

    const handleAccountDeletion = async () => {
        if (await isAuthenticated()) {
            await fetch('/api/account/delete', {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'X-CSRF-TOKEN': await getCsrfToken()
                }
            });
        }
        showSnackbar({
            message: "Account deletion successful",
        });
        navigate('/login');
    }

    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Dialog
                open={deletionAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete " + user.email}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want irreversibly delete your sp34kup.de account with all its information?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeletionAlert(false)}>Keep Account</Button>
                    <Button color='error' onClick={handleAccountDeletion}>Delete Account</Button>
                </DialogActions>
            </Dialog>
            {/* User Avatar Section */}
            <Grid container direction={'column'} spacing={3} mb={4}>
                <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Avatar src={user.avatar} onClick={() => navigate("/account/avatar")} sx={{ width: 100, height: 100 }}>
                        <UploadIcon fontSize='large' />
                    </Avatar>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h4">
                        Welcome {user.nickname ? user.nickname : user.email}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography>
                        You can manage your account information here.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction={'column'} spacing={3}>
                <Grid container item direction={'column'} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={12} sx={{ justifyContent: 'left' }}>
                        <Typography variant="h5">
                            Account Settings
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        {
                            <ButtonGroup orientation="vertical" aria-label="Vertical button group" fullWidth>
                                <SettingsButton represents='Nickname' onClick={() => navigate('/account/nickname')}>
                                    {user.nickname ? user.nickname : <><ErrorIcon color='warning' sx={{ marginRight: 1 }} />Nickname not set</>}
                                </SettingsButton>
                                <SettingsButton represents='Email' onClick={() => navigate('/account/email?email=' + user.email)}>
                                    {user.email}
                                </SettingsButton>
                                <SettingsButton represents='Password' onClick={() => navigate('/account/password')}>
                                    ******
                                </SettingsButton>
                                <SettingsButton represents='Verified' onClick={() => { navigate("/account/verify") }}>
                                    {user.verified ? <><CheckCircleIcon color='success' sx={{ marginRight: 1 }} />  Verified</> : <><ErrorIcon color='warning' sx={{ marginRight: 1 }} />Not verified</>}
                                </SettingsButton>
                            </ButtonGroup>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Paper variant='outlined' sx={{ p: 3, width: '100%', m: 'auto', borderColor: 'error.main' }}>
                        <Typography variant="h5">
                            Danger Zone
                        </Typography>
                        <Typography>
                            When you delete your account, all data will be lost. Currently, there is no way to restore the data.
                        </Typography>
                        <Button variant='outlined' color='error' onClick={() => setDeletionAlert(true)}>
                            Delete account
                        </Button>
                    </Paper>
                </Grid>
            </Grid>


            {/* Additional sections like password change, linked accounts, etc., can be added here */}
        </Container>
    );
};

export default AccountPage;