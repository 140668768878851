import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Email() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');

    return (
        <Container maxWidth="md">
            <Grid
                container
                spacing={2}
                direction={'column'}
                p={4}
            >
                <Grid item>
                    <Typography variant="h4" align="center">
                        Linked Email
                    </Typography>
                </Grid>
                <Grid item mb={3}>
                    <Typography align="center">
                        This is the email that is linked to your account. You cannot change it.
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField name="email" required fullWidth disabled value={email}></TextField>
                </Grid>
                <Grid item>
                    <Button variant="contained" fullWidth onClick={() => navigate("/account")}>Back</Button>
                </Grid>
            </Grid>
        </Container>
    );
}