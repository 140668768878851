import { FieldErrorDto } from "common/dto/helper/FieldErrorDto";

export interface FieldErrorsDto {
    fieldErrors: FieldErrorDto[];
}

export function isFieldErrorsDto(payload: any): payload is FieldErrorsDto {
    return (
        payload &&
        Array.isArray(payload.fieldErrors) &&
        payload.fieldErrors.every(
            (fieldError: any) => 
                typeof fieldError.field === "string" &&
                typeof fieldError.error === "string"
        )
    );
}
