import { Box, CircularProgress } from '@mui/material';
import React, { useState, useEffect, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import ResponsiveAppBar from 'common/components/ResponsiveAppBar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'common/store/store';
import {fetchUserData} from 'common/utils/utils'

interface ProtectedRouteProps {
    element: ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
    const [authenticated, setAuthenticated] = useState<boolean | null>(null); // null for loading state
    const location = useLocation();

    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const response = await fetch('/api/auth/check-auth', {
                    method: 'GET',
                    credentials: 'include'
                });

                if (response.ok) {
                    setAuthenticated(true);
                } else {
                    setAuthenticated(false);
                }
            } catch (error) {
                console.error('Error checking authentication status:', error);
                setAuthenticated(false);
            }
        };

        checkAuthStatus();
        
        if (user.email === undefined) {
            fetchUserData(dispatch);
        }
    }, []);

    if (authenticated === null) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return authenticated ? (
        <>
            <ResponsiveAppBar />
            {element}
        </>
        ) : (
            <Navigate to="/login" state={{ from: location }} />
        );
};

export default ProtectedRoute;