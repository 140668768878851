import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDto } from 'common/dto/payload/UserDto';

const initialState: UserDto = {
  email: undefined,
  nickname: undefined,
  verified: undefined,
  avatar: undefined
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserDto>) {
      return { ...state, ...action.payload }
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setNickname(state, action: PayloadAction<string>) {
      state.nickname = action.payload;
    },
    setVerified(state, action: PayloadAction<boolean>) {
      state.verified = action.payload;
    },
  },
});

export const { setUser, setEmail, setNickname, setVerified } = userSlice.actions;
export default userSlice.reducer;
