import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { useSnackbar } from "common/components/SnackbarProvider";
import { PasswordChangeDto } from "common/dto/request/PasswordChangeDto";
import { ResponseDto } from "common/dto/response/ResponseDto";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCsrfToken } from "common/utils/utils";

export default function Password() {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newConfirmationPassword, setNewConfirmationPassword] = useState("");

    const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOldPassword(event.target.value);
    };

    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
    };

    const handleNewConfirmationPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewConfirmationPassword(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const passwordChangeDto: PasswordChangeDto = {
            oldPassword,
            newPassword,
            newConfirmationPassword
        }
        
        try {
            const response = await fetch('/api/account/change-password', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': await getCsrfToken(),
                },
                body: JSON.stringify(passwordChangeDto),
                credentials: 'include'
            });
            const data: ResponseDto = await response.json();

            if (data.status === "success") {
                showSnackbar({
                    message: "Password changed successfully",
                });
                navigate("/account")
            } else {
                setOldPassword("")
                setNewPassword("")
                setNewConfirmationPassword("")
                showSnackbar({
                    message: "Failed to change password",
                    severity: "error"
                });
            }

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    return (
        <Container maxWidth="md">
            <Grid
                container
                spacing={2}
                direction={'column'}
                p={4}
                component={'form'}
                onSubmit={handleSubmit}
            >
                <Grid item>
                    <Typography variant="h4" align="center">
                        Change Password
                    </Typography>
                </Grid>
                <Grid item mb={3}>
                    <Typography align="center">
                        Here you can change your password.
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField name="oldPassword" label='Old Password' required fullWidth type="password" value={oldPassword} onChange={handleOldPasswordChange}></TextField>
                </Grid>
                <Grid item>
                    <TextField name="newPassword" label='New Password' required fullWidth type="password" value={newPassword} onChange={handleNewPasswordChange}></TextField>
                </Grid>
                <Grid item>
                    <TextField name="newConfirmationPassword" label='New Password' required fullWidth type="password" value={newConfirmationPassword} onChange={handleNewConfirmationPasswordChange}></TextField>
                </Grid>
                <Grid item>
                    <Button variant="contained" type='submit' fullWidth>Change password</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" fullWidth onClick={() => navigate("/account")}>Back</Button>
                </Grid>
            </Grid>
        </Container>
    );
}
