import { Button, ButtonProps, Grid, Typography } from '@mui/material';
import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface SettingsButtonProps extends ButtonProps {
    // Add any custom props if needed
    represents?: string,
}

const SettingsButton: React.FC<SettingsButtonProps> = ({ children, ...props }) => {

    return (
        <Button fullWidth sx={{ height: 'auto' }} onClick={props.onClick}>
            <Grid container justifyContent={'space-between'} alignItems={'center'} sx={{
                flexDirection: { xs: 'column', sm: 'row' },
                textAlign: { xs: 'center', sm: 'left' },
                height: { xs: 'auto', sm: '50px' },
                p: 1,
                gap: { xs: 1, sm: 0 }
            }}>
                <Grid item xs={3} display={'flex'} justifyContent={'left'}>
                    <Typography sx={{ color: 'text.secondary' }}>
                        {props.represents}
                    </Typography>
                </Grid>
                <Grid item xs={6} display={'flex'} sx={{ textTransform: 'none' }} justifyContent={'left'}>
                    {children}
                </Grid>
                <Grid item xs={2} display={'flex'} alignItems={'center'} justifyContent={'right'}>
                    <ArrowForwardIosIcon />
                </Grid>
            </Grid>
        </Button>
    )
};

export default SettingsButton;