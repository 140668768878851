import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Typography from '@mui/material/Typography';
import { Card, CardActionArea, CardContent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'common/components/SnackbarProvider';
import { ErrorResponseDto } from 'common/dto/response/ErrorResponseDto';
import { FieldErrorsDto, isFieldErrorsDto } from 'common/dto/payload/FieldErrorsDto';
import Copyright from 'common/components/Copyright';


export default function Login() {

  const [usernameError, setUsernameError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)

  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();

  const handleOnChangePassword = () => {
    setPasswordError(false);
  }

  const handleOnChangeUsername = () => {
    setUsernameError(false);
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userLoginData = {
      username: data.get('username'),
      password: data.get('password')
    }

    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userLoginData),
        credentials: 'include'
      });

      const contentType = response.headers.get('Content-Type')

      let body;

      if (contentType && contentType.includes('application/json')) {
        body = await response.json();
        if (response.ok) {
          showSnackbar({
            message: "Login successful",
          });
          navigate('/');
        } else {
          const errorResponse: ErrorResponseDto = body;
          if (errorResponse.payload) {
            if (isFieldErrorsDto(errorResponse.payload)) {
              const payload: FieldErrorsDto = errorResponse.payload;
              const usernameErrorStr = payload.fieldErrors.find(error => error.field === 'username')
              if (usernameErrorStr) {
                setUsernameError(true);
              }
              const passwordErrorStr = payload.fieldErrors.find(error => error.field === 'password')
              if (passwordErrorStr) {
                setPasswordError(true);
              }
            }
          } else {
            showSnackbar({
              message: errorResponse.message,
              severity: "error"
            });
          }

        }
      } else {
        body = await response.text();
      }
    } catch (error) {
      // Show alert and leave for user to remove
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const handleCardPress = () => {
    window.open('https://discord.gg/KEu2afpjkY');
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid container
        item
        xs={false}
        sm={4}
        md={7}
        sx={{ justifyContent: 'center', alignContent: 'center' }}
      >
        <Card elevation={6} onClick={handleCardPress} sx={{ maxWidth: 345, height: 'match-content' }}>
          <CardActionArea>
            <CardContent>
              <FontAwesomeIcon icon={faDiscord} size="lg" />
              <Typography gutterBottom variant="h5" component="div">
                Join our Discord Server
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Tap on the card to join our friendly community on the sp34kup Discord server.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1 , bgcolor: 'warning.main'}} >
            <VpnKeyIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="email"
              autoFocus
              error={usernameError}
              onChange={handleOnChangeUsername}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={passwordError}
              onChange={handleOnChangePassword}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Button onClick={() => navigate("/reset-password")}>
                  Forgot password?
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={() => navigate("/register")}>
                  Don't have an account? Sign Up
                </Button>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}