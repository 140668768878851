import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { useSnackbar } from "common/components/SnackbarProvider";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SuccessResponseDto } from "common/dto/response/SuccessResopnseDto";
import { UserDto } from "common/dto/payload/UserDto";
import { getCsrfToken } from "common/utils/utils";
import { FieldErrorsDto } from "common/dto/payload/FieldErrorsDto";

export default function Nickname() {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [text, setText] = useState('')

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const nickname = data.get('nickname')?.toString();
        const userDto: UserDto = {
            email: undefined,
            nickname: nickname ? nickname : undefined,
            verified: undefined,
            avatar: undefined
        }

        try {
            const response = await fetch('/api/account/set-nickname', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': await getCsrfToken()
                },
                body: JSON.stringify(userDto),
                credentials: 'include'
            });
            const data: SuccessResponseDto = await response.json();
            if (data.status === 'success') {
                showSnackbar({
                    message: 'Nickname changed successfully',
                });
                navigate("/account")
            } else {
                const fieldErrors: FieldErrorsDto = data.payload;
                let message = data.message;
                if (fieldErrors) {
                    console.log(fieldErrors.fieldErrors[0].error)
                    console.log(fieldErrors.fieldErrors[0].field)
                }
                showSnackbar({
                    message: message,
                    severity: 'error'
                });
            }

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    return (
        <Container maxWidth="md">
            <Grid
                container
                spacing={2}
                direction={'column'}
                p={4}
                component={'form'}
                onSubmit={handleSubmit}
            >
                <Grid item>
                    <Typography variant="h4" align="center">
                        Pick a Nickname
                    </Typography>
                </Grid>
                <Grid item mb={3}>
                    <Typography align="center">
                        You can change your nickname as often as you wish. Feel free and be creative.
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField name="nickname" label='Nickname' required fullWidth onChange={handleChange}></TextField>
                </Grid>
                <Grid item>
                    <Button variant="contained" type='submit' fullWidth color={text ? "success" : "primary"}>Set nickname</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" fullWidth onClick={() => navigate("/account")}>Back</Button>
                </Grid>
            </Grid>
        </Container>
    );
}