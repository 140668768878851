// SnackbarContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Snackbar, Alert, AlertProps } from '@mui/material';

interface SnackbarOptions {
  message: string,
  severity?: AlertProps['severity'],
  duration?: number,
  onCloseCallback?: () => void
}

interface SnackbarContextType {
  showSnackbar: (options: SnackbarOptions) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertProps['severity']>('success');
  const [duration, setDuration] = useState(2000);
  const [onCloseCallback, setOnCloseCallback] = useState<(() => void) | undefined>(undefined);

  const showSnackbar: SnackbarContextType['showSnackbar'] = ({
    message,
    severity = 'success', 
    duration = 2000,
    onCloseCallback
  }) => {
    setMessage(message);
    setSeverity(severity);
    setDuration(duration);
    setOnCloseCallback(onCloseCallback);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (onCloseCallback){
      onCloseCallback();
    }
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar open={open} autoHideDuration={duration} onClose={handleClose} anchorOrigin={{vertical:'bottom', horizontal:'center'}}>
        <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
