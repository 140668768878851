import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'common/components/SnackbarProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';

const VerifyAccount = () => {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    const [searchParams] = useSearchParams();
    const verificationToken = searchParams.get('verificationToken');

    const handleVerification = async () => {
        try {
            const response = await fetch('/api/account/verify/'+verificationToken, {
              method: 'PATCH',
              credentials: 'include'
            });
      
            const contentType = response.headers.get('Content-Type')
      
            if (response.ok && contentType && contentType.includes('application/json')) {
                if (response.ok) {
                    setTimeout(() => {
                        showSnackbar({
                            message:"Accout verification successful",
                          });
                        navigate('/login');
                    }, 2000);
                }
            } else {
                setTimeout(() => {
                    showSnackbar({
                        message:"Failed to verify accout",
                        severity:"error"
                      });
                    navigate('/login');
                }, 2000);
            }
        } catch (error) {
            setTimeout(() => {
                showSnackbar({
                    message:"Failed to verify accout",
                    severity:"error"
                  });
                navigate('/login');
            }, 2000);
        }
    }
    handleVerification();

    return (
        <div>
            <h2>Your account is beeing verified</h2>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        </div>
    );
};

export default VerifyAccount;
