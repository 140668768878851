import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LoginIcon from '@mui/icons-material/Login';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'common/components/SnackbarProvider';
import { ErrorResponseDto } from 'common/dto/response/ErrorResponseDto';
import { useState } from 'react';
import { CircularProgress, FormControl, FormHelperText } from '@mui/material';
import { FieldErrorsDto, isFieldErrorsDto } from 'common/dto/payload/FieldErrorsDto';
import Copyright from 'common/components/Copyright';

export default function Register() {

  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [emailError, setEmailError] = useState(false)
  const [confirmationEmailError, setConfirmationEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [emailErrorText, setEmailErrorText] = useState('')
  const [loading, setLoading] = useState(false)

  const handleOnChangePassword = () => {
    setPasswordError(false);
  }
  const handleOnChangeEmail = () => {
    setEmailError(false);
    setEmailErrorText('')
  }
  const handleOnChangeConformationEmail = () => {
    setConfirmationEmailError(false);
  }

  const handleSubmit = async (e: { preventDefault: () => void; currentTarget: HTMLFormElement | undefined; }) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData(e.currentTarget);

    const userRegistrationData = {
      username: formData.get('username'),
      password: formData.get('password'),
      email: formData.get('email'),
      confirmationEmail: formData.get('confirmationEmail'),
    };

    try {
      const response = await fetch('/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userRegistrationData),
        credentials: 'include'
      });

      const contentType = response.headers.get('Content-Type')

      let body;

      if (contentType && contentType.includes('application/json')) {
        body = await response.json();
        if (response.ok) {
          showSnackbar({
            message: "Registration successful",
          });
          navigate("/login")
        } else {
          const errorResponse: ErrorResponseDto = body;
          if (errorResponse.payload) {
            if (isFieldErrorsDto(errorResponse.payload)) {
              const payload: FieldErrorsDto = errorResponse.payload;
              const emailError = payload.fieldErrors.find(error => error.field === 'email')
              if (emailError) {
                setEmailError(true)
                setEmailErrorText(emailError.error)
              }
              const confirmationEmailError = payload.fieldErrors.find(error => error.field === 'confirmationEmail')
              if (confirmationEmailError) {
                setConfirmationEmailError(true)
              }
              const passwordError = payload.fieldErrors.find(error => error.field === 'password')
              if (passwordError) {
                setPasswordError(true)
              }
            }
          } else {
            showSnackbar({
              message: errorResponse.message
            })
          }
        }
      } else {
        body = await response.text();
      }
    } catch (error) {
      // Show alert and leave for user to remove
      console.error('There was a problem with the fetch operation:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'warning.main' }}>
          <LoginIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl error={emailError} fullWidth>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={emailError}
                  onChange={handleOnChangeEmail}
                />
                <FormHelperText color='error'>{emailErrorText}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="confirmationEmail"
                label="Confirm Email Address"
                name="confirmationEmail"
                error={confirmationEmailError}
                onChange={handleOnChangeConformationEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                error={passwordError}
                onChange={handleOnChangePassword}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              'Sign Up'
            )}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => navigate("/login")}>
                Already have an account? Sign in
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}