import './App.css';
import { Routes, Route} from 'react-router-dom';
import Home from 'common/components/pages/Home';
import Login from 'common/components/pages/Login';
import Register from 'common/components/pages/Register';
import ProtectedRoute from 'common/components/ProtectedRoute';
import { SnackbarProvider } from 'common/components/SnackbarProvider';
import ResetPassword from 'common/components/pages/ResetPassword';
import VerifyingAccount from 'common/components/pages/VerifyingAccount';
import Account from 'common/components/pages/Account';
import Password from 'common/components/pages/Password';
import Verify from 'common/components/pages/Verify';
import Nickname from 'common/components/pages/Nickname';
import Email from 'common/components/pages/Email';
import Avatar from 'common/components/pages/Avatar';

const App = () => {
  return (
    <SnackbarProvider>
      <Routes>
        <Route path='/' element={<ProtectedRoute element={<Home />} />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/account/verifying' element={<VerifyingAccount />} />
        <Route path='/account' element={<ProtectedRoute element={<Account />} />} />
        <Route path='/account/nickname' element={<ProtectedRoute element={<Nickname />} />} />
        <Route path='/account/email' element={<ProtectedRoute element={<Email />} />} />
        <Route path='/account/password' element={<ProtectedRoute element={<Password />} />} />
        <Route path='/account/verify' element={<ProtectedRoute element={<Verify />} />} />
        <Route path='/account/avatar' element={<ProtectedRoute element={<Avatar />} />} />
      </Routes>
    </SnackbarProvider>
  );
};

export default App;
