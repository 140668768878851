import React from 'react';
import { Typography } from '@mui/material';

const Home: React.FC = () => {

  return (
    <div>
      <Typography variant='h2'>
        Welcome to sp34kup.de.
      </Typography>
    </div>
  );
};

export default Home;
