import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../SnackbarProvider";
import { Avatar as MuiAvatar, Button, Container, Grid, Typography, Input, FormLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "common/store/store";
import { ChangeEvent, FormEvent, useState } from "react";
import { fetchUserData, getCsrfToken } from "common/utils/utils";

const Avatar = () => {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string | null>(null);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]
            setSelectedFile(file);

            // Generate a preview of the image
            const reader = new FileReader();
            reader.onloadend = () => {
                // Cast reader.result to a string, as we're using DataURL
                if (typeof reader.result === 'string') {
                    setPreview(reader.result); // Set the base64 string as the preview
                };
            };
            reader.readAsDataURL(file); // Convert file to base64 URL

            // Clear file in input value when it is stored in state. This allows to trigger onChange even if the same file is selected again.
            event.target.value = ''
        };
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (selectedFile) {
            const formData = new FormData();
            formData.append("file", selectedFile);

            try {
                const response = await fetch('/api/account/upload-avatar', {
                    method: 'PUT',
                    headers: {
                        'X-CSRF-TOKEN': await getCsrfToken(),
                    },
                    body: formData,
                    credentials: 'include'
                });
                if (response.ok) {
                    fetchUserData(dispatch);
                    resetSelectedFile();
                    showSnackbar({
                        message: "Image saved"
                    });
                } else {
                    showSnackbar({
                        message: "An error occured",
                        severity: "error"
                    });
                }
            } catch (error) {
                showSnackbar({
                    message: "An error occured",
                    severity: "error"
                });
            }
        } else {
            showSnackbar({
                message: "Please select an image file first",
                severity: "error"
            });
        }
    };

    const resetSelectedFile = () => {
        setSelectedFile(null);
        setPreview(null);
    }

    const handleDelete = async () => {
        try {
            const response = await fetch('/api/account/delete-avatar', {
                method: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': await getCsrfToken(),
                },
                credentials: 'include'
            });
            if (response.ok) {
                fetchUserData(dispatch);
                showSnackbar({
                    message: "Avatar deleted",
                });
            } else {
                showSnackbar({
                    message: "An error occured",
                    severity: "error"
                });
            }
        } catch (error) {
            showSnackbar({
                message: "An error occured",
                severity: "error"
            });
        }
    };


    return (
        <Container maxWidth="md">
            <Grid
                container
                spacing={2}
                direction={'column'}
                p={4}
            >
                <Grid item>
                    <Typography variant="h4" align="center">
                        Do you like your avatar?
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography align="center">
                        You can upload any avatar as long as it is a 256x256 pixel PNG.
                    </Typography>
                </Grid>
                <Grid item container justifyContent={'center'}>
                    <MuiAvatar
                        src={preview ? preview : user.avatar}
                        sx={{ width: 256, height: 256 }}
                    />
                </Grid>
                {selectedFile &&
                    <Grid item>
                        <Typography align='center'>{selectedFile.name}</Typography>
                    </Grid>
                }
                <Grid item component='form' onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    <Input
                        type="file"
                        style={{ display: 'none' }}
                        id="avatar-upload"
                        onChange={handleFileChange}
                        inputProps={{ accept: "image/png" }}
                    />
                    {selectedFile && <Button color="success" variant="contained" fullWidth type="submit" sx={{ mb: 2 }}>Save</Button>}
                    <FormLabel htmlFor='avatar-upload'>
                        <Button variant="contained" fullWidth component="span">
                            Choose new image
                        </Button>
                    </FormLabel>
                </Grid>
                <Grid item>
                    {selectedFile
                    ? <Button variant="contained" fullWidth onClick={resetSelectedFile}>Clear chosen image</Button>
                    : <Button variant="contained" fullWidth onClick={handleDelete}>Delete avatar</Button>
                    }
                </Grid>
                <Grid item>
                    <Button variant="contained" fullWidth onClick={() => navigate("/account")}>Back</Button>
                </Grid>
            </Grid>
        </Container>
    );
}
export default Avatar;