import { Button, CircularProgress, Container, Grid, Typography } from "@mui/material";
import { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "common/store/store";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { getCsrfToken } from "common/utils/utils";
import { ResponseDto } from "common/dto/response/ResponseDto";
import { useSnackbar } from "common/components/SnackbarProvider";

export default function Verify() {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const user = useSelector((state: RootState) => state.user);


    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await fetch('/api/account/request-verification', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': await getCsrfToken(),
                },
                credentials: 'include'
            });
            const data: ResponseDto = await response.json();

            if (data.status === "success") {
                showSnackbar({
                    message: "Verification email sent successfully",
                });
                navigate("/account")
            } else {
                showSnackbar({
                    message: "Failed sending verification email",
                });
            }

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    return (
        <Container maxWidth="md">
            <Grid
                container
                spacing={2}
                direction={'column'}
                p={4}
                component={'form'}
                onSubmit={handleSubmit}
            >
                <Grid item>
                    <Typography variant="h4" align="center">
                        Account Verification
                    </Typography>
                </Grid>
                <Grid item mb={3}>
                    <Typography align="center">
                        When your account is verfied, you have access to more functionalities on sp34kup.de.
                    </Typography>
                </Grid>
                <Grid item container justifyContent={'center'}>
                    {user.verified ? <CheckCircleIcon fontSize="large" color='success' sx={{ marginRight: 1 }} /> : <ErrorIcon fontSize="large" color='warning' sx={{ marginRight: 1 }} />}
                </Grid>
                <Grid item mb={3} container justifyContent={'center'} alignItems={'center'}>
                    <Typography variant="h6" align="center">{user.verified ? "Your account is already verified!" : "Your account is not yet verified."} </Typography>
                </Grid>
                {!user.verified ?
                    <Grid item mb={3}>
                        <Typography align="center">
                            Please check your mailbox on {user.email} for a verification email. In case the old verification link is no longer valid, you can request a new verification link below.
                            If you do not receive any email, please contact our support.
                        </Typography>
                    </Grid> :
                    <></>}
                <Grid item>
                    <Button variant="contained" fullWidth type='submit' disabled={user.verified || loading} >{loading ? <CircularProgress size={24}/> : "Send verification email"}</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" fullWidth onClick={() => { navigate("/account") }}>Back</Button>
                </Grid>
            </Grid>
        </Container>
    );
}